"use client";
import { cx } from "@tracksuit/utils";
import mixins from "$src/styles/mixins.module.css";
import styles from "$src/app/layout.module.css";
import { ErrorPage } from "$src/components/error-page/error-page";
import type Error from "next/error";

export default function GlobalError({ error }: Readonly<{ error: Error }>) {
  return (
    <html lang="en">
      <head>
        <title>Tracksuit</title>
        <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      </head>
      <body>
        <main className={cx(styles.page, mixins["grid-page"])}>
          <ErrorPage text="Something went wrong" error={error} />
        </main>
      </body>
    </html>
  );
}
